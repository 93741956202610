import { Avatar, Card, CardContent, Container, Grid, List } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HorizontalBanner from "../../Components/HorizontalBanner/HorizontalBanner";
import Categories from "../../Components/Categories/Categories";
import Marqueecomponent from "../../Components/LatestContents/Marqueecomponent";
import Trandingcard from "../../Components/TrandingCard/Trandingcard";
import { Link } from "react-router-dom";
import Latestproduct from "../../Components/LatestContents/Latestproduct";
import AdModal from "../../Components/AdModal/AdModal";
import Slider from "react-slick";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import style from "./style.module.css";
import MainContentHeader from "./MainContentHeader";
import { api } from "../../../../Helper/Data";
import { NavLink } from "reactstrap";
import { useSelector } from "react-redux";
import CategoryCard from './CategoryCard'
const axios = require("axios").default;

export default function Home() {
  const categoriesStore = useSelector((state) => state.CategoryState.data);
  const customSlider = useRef();
  const [countryCode, setCountryCode] = useState("in");
  const [newsUpdate, setNewsUpdate] = useState([]);
  const [horizontalTab, setHorizontalTab] = useState(false);
  const [latestLeads, setLatestLeads] = useState([]);
  const [allData, setAllData] = useState({});
  const [adModal, setAdModal] = useState(true);
  const { innerWidth: width } = window;

  const contryCodeObj = [
    { name: "All Country", code: "" },
    { name: "United Arab Emirates", code: "ae" },
    { name: "Argentina", code: "ar" },
    { name: "Austria", code: "at" },
    { name: "Australia", code: "au" },
    { name: "Belgium", code: "be" },
    { name: "Bulgaria", code: "bg" },
    { name: "Brazil", code: "br" },
    { name: "Canada", code: "ca" },
    { name: "Switzerland", code: "ch" },
    { name: "China", code: "cn" },
    { name: "Colombia", code: "co" },
    { name: "Cuba", code: "cu" },
    { name: "Germany", code: "de" },
    { name: "Egypt", code: "eg" },
    { name: "France", code: "fr" },
    { name: "Great Britain", code: "gb" },
    { name: "Greece", code: "gr" },
    { name: "Hong Kong", code: "hk" },
    { name: "Hungary", code: "hu" },
    { name: "Indonesia", code: "id" },
    { name: "Ireland", code: "ie" },
    { name: "Israel", code: "il" },
    { name: "India", code: "in" },
    { name: "Italy", code: "it" },
    { name: "Japan", code: "jp" },
    { name: "Korea, Republic of", code: "kr" },
    { name: "Lithuania", code: "lt" },
    { name: "Morocco", code: "ma" },
    { name: "Mexico", code: "mx" },
    { name: "Malaysia", code: "my" },
    { name: "Nigeria", code: "ng" },
    { name: "Netherlands", code: "nl" },
    { name: "Norway", code: "no" },
    { name: "New Zealand", code: "nz" },
    { name: "Philippines", code: "ph" },
    { name: "Poland", code: "pl" },
    { name: "Portugal", code: "pt" },
    { name: "Romania", code: "ro" },
    { name: "Serbia", code: "rs" },
    { name: "Russian Federation", code: "ru" },
    { name: "Saudi Arabia", code: "sa" },
    { name: "Sweden", code: "se" },
    { name: "Singapore", code: "sg" },
    { name: "Slovenia", code: "si" },
    { name: "Slovakia", code: "sk" },
    { name: "Thailand", code: "th" },
    { name: "Türkiye", code: "tr" },
    { name: "Taiwan", code: "tw" },
    { name: "Ukraine", code: "ua" },
    { name: "America", code: "us" },
    { name: "Venezuela", code: "ve" },
    { name: "South Africa", code: "za" },
  ];

  useEffect(() => {
    // console.log(allData);
    width < 900 ? setHorizontalTab(true) : setHorizontalTab(false);
    console.error()
    // getting News Update
    axios
      .post(api.url + "/get-news", { countryCode: countryCode })
      .then(function (response) {
        // console.log(response);
        const createList = response.data.articles.map((el, i) => {
          return { title: el.title, url: el.url, source: el.source.name, img: el.urlToImage };
        });
        setNewsUpdate(createList);
      })
      .catch(function (error) {
        console.log(error);
      });

    // latest leads
    axios
      .post(api.url + "/get-leads", { page: 1 })
      .then(function (response) {
        // console.log(response);
        setLatestLeads(response.data.leads);
      })
      .catch(function (error) {
        console.log(error);
      });

    // trending Products
    axios
      .post(api.url + "/get-data-for-trend-hot-premium-testimonial", { id: "id" })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          setAllData({
            trendingProducts: response.data.product,
            premiumCompany: response.data.companies,
            hotProducts: response.data.products,
            testimonial: response.data.testimonials,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [countryCode, width]);

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 10000,
    arrows: false,
    slidesToScroll: 1,
    cssEase: "linear",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fontSize: 8
        },
      },
    ],
  };

  const newsSettings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    slidesToScroll: 1,
    cssEase: "linear",
    slidesToShow: newsUpdate.length > 2 ? 2 : newsUpdate.length,
    responsive: [
      {
        breakpoint: 504,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const preBrand = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 20000,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    rows: 2,
    slidesPerRow: 5,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesPerRow: 2,
        },
      },
    ],
  };

  const getNews = (e) => {
    console.log(e.target.value);
    setCountryCode(e.target.value);
  };

  window.addEventListener("resize", function (e) {
    e.target.innerWidth < 900 ? setHorizontalTab(true) : setHorizontalTab(false);
  });

  return (
    categoriesStore.length ?
      <Container>
        {/* Main Content Headers */}
        <MainContentHeader data={categoriesStore} />

        {/* Horizontal Banner */}
        <HorizontalBanner></HorizontalBanner>

        {/* <div style={{ marginTop: "10px", padding: "0px 20px" }}>
          <img src={api.bannerUrl + "ban s.gif"} style={{ width: "100%", height: "150px" }} alt="" />
        </div> */}
        {/* <AdModal setModal={(e)=>setAdModal(e)} open={adModal}/> */}

        {/* Latests Contents */}
        <Container className="py-3">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div>
                <Card className="card-outline card-danger">
                  <div className="card-header">
                    <h3 className={"card-title " + style.cardTitle}>Latest Buying Leads</h3>
                    <div className="card-tools ">
                      <Link className="font-main" to="/importers">
                        -View More-
                      </Link>
                    </div>
                  </div>
                  <CardContent className="py-2">
                    {latestLeads.length ? <Marqueecomponent data={latestLeads} height={240}></Marqueecomponent> : ""}
                  </CardContent>
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <Card className="card-outline card-danger">
                  <div className="card-header">
                    <h3 className={"card-title " + style.cardTitle}>Hot Products</h3>
                    <div className="card-tools ">
                      <Link className="font-main" to="/product-categories">
                        -View More-
                      </Link>
                    </div>
                  </div>
                  <CardContent className="py-1">
                    <List style={{ fontSize: "small" }}>
                      <Grid container spacing={1}>
                        {allData.hotProducts
                          ? allData.hotProducts.map((el, i) => {
                            return <Latestproduct key={i} data={el} />;
                          })
                          : ""}
                      </Grid>
                    </List>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Container>

        {/* Featured Product */}
        <Container className="py-3">
          <Card className="card-outline card-danger">
            <div className="card-header">
              <h3 className={"card-title " + style.cardTitle}>Trending Products</h3>
              <div className="card-tools ">
                <Link className="font-main" to="/product-categories">
                  -View More-
                </Link>
              </div>
            </div>
            <CardContent className="text-secondary py-2">
              <Grid container spacing={2}>
                {allData.trendingProducts
                  ? allData.trendingProducts.map((el, i) => {
                    return (
                      <Grid key={i} item xs={6} sm={6} md={3} className={"text-center " + style.trendItem}>
                        <Trandingcard data={el}></Trandingcard>
                      </Grid>
                    );
                  })
                  : ""}
              </Grid>
            </CardContent>
          </Card>
        </Container>

        {/* Categories Content */}
        <Container className="py-3">
          <Card className="card-outline card-danger">
            <div className="card-header">
              <h3 className={"card-title " + style.cardTitle}>
                {" "}
                Browse By <span className="text-main">Categories</span>{" "}
              </h3>
              {/* <div className="card-tools ">
                <Link className="font-main" to="/buyers">
                  -View More-
                </Link>
              </div> */}
            </div>
            <CardContent>{<Categories horizontal={horizontalTab}></Categories>}</CardContent>
          </Card>
        </Container>

        {/* Food & Beverage Category Card */}
        {
          categoriesStore.map((el, i) => {
            return el.category === 'Food & Beverage' ? <CategoryCard key={i} imageUrl={api.categorycard+"breverage.jpg"} data={el} /> : ""
          })
        }

        {/* Agricultural Products Category Card */}

        {
          categoriesStore.map((el, i) => {
            return el.category === 'Apparel & Fashion ' ? <CategoryCard key={i} imageUrl={api.categorycard+"agri.jpg"} data={el} /> : ""
          })
        }

        {/* Realestate, Building, Construction Material & Services Category Card */}
        {
          categoriesStore.map((el, i) => {
            return el.category === 'Realestate, Building, Construction Material & Services' ? <CategoryCard key={i} imageUrl={api.categorycard+"cunst.jpg"} data={el} /> : ""
          })
        }

        {/* Cosmetics & Personal Care Category Card */}
        {
          categoriesStore.map((el, i) => {
            return el.category === 'Cosmetics & Personal Care' ? <CategoryCard key={i} imageUrl={api.categorycard+"spics.jpg"} data={el} /> : ""
          })
        }
        
        {/* Cooking Spices And Masala Category Card */}
        {
          categoriesStore.map((el, i) => {
            return el.category === 'Cooking Spices And Masala' ? <CategoryCard key={i} imageUrl={api.categorycard+"spics.jpg"} data={el} /> : ""
          })
        }
        
        {/* Industrial Chemicals & Supplies Category Card */}
        {
          categoriesStore.map((el, i) => {
            return el.category === 'Industrial Chemicals & Supplies' ? <CategoryCard key={i} imageUrl={api.categorycard+"spics.jpg"} data={el} /> : ""
          })
        }

        <Container className="py-3">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card className="card-outline card-danger">
                <div className="card-header">
                  <h3 className={"text-center m-0 " + style.cardTitle}>Premium Brands</h3>
                </div>
                <CardContent className={"py-2 text-secondary " + style.premiumBrand}>
                  <Slider {...preBrand} ref={(slider) => (customSlider.current = slider)}>
                    {allData.premiumCompany
                      ? allData.premiumCompany.map((el, i) => {
                        return (
                          <div key={i}>
                            <span
                              className={style.premiumItem}
                            >
                              <img src={api.companyUrl + el.companyLogo} alt="" />
                            </span>
                          </div>
                        );
                      })
                      : ""}
                  </Slider>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className="card-outline card-danger">
                <div className="card-header">
                  <h3 className={"card-title " + style.cardTitle}>Regional Channels</h3>
                  {/* <div className="card-tools ">
                    <Link className="font-main" to="/buyers">
                      -View More-
                    </Link>
                  </div> */}
                </div>
                <CardContent className="py-2">
                  <Grid container spacing={1}>
                    {[
                      { name: "India", code: "in", flag: "https://flagcdn.com/in.svg" },
                      {
                        name: "China", code: "cn",
                        flag: "https://e7.pngegg.com/pngimages/4/732/png-clipart-flag-of-china-flag-of-the-republic-of-china-flag-of-pakistan-chinese-material-flag-world.png",
                      },
                      { name: "Turkey", code: "tr", flag: "https://flagcdn.com/tr.svg" },
                      { name: "USA", code: "us", flag: "https://flagcdn.com/us.svg" },
                      { name: "Japan", code: "jp", flag: "https://flagcdn.com/jp.svg" },
                      { name: "Malaysia", code: "my", flag: "https://flagcdn.com/my.svg" },
                      { name: "Egypt", code: "eg", flag: "https://flagcdn.com/eg.svg" },
                      { name: "Germany", code: "de", flag: "https://flagcdn.com/de.svg" },
                    ].map((el, i) => {
                      return (
                        <Grid key={i} item xs={6}>
                          <Link className={style.regionalItem} to={`/country/${el.code}`}>
                            <Avatar sx={{ width: 30, height: 30 }} alt={el.name} src={el.flag} />
                            <p className="mx-2"><strong>{el.name}</strong></p>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className="card-outline card-danger">
                <div className="card-header">
                  <h3 className={"card-title " + style.cardTitle}>Upcoming Trade News</h3>
                  <div className="card-tools ">
                    <select name="" id="" value={countryCode} onChange={getNews}>
                      {contryCodeObj.map((el, i) => {
                        return (
                          <option key={i} value={el.code}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <CardContent className="py-2" color="text.secondary">
                  {newsUpdate.length ? (
                    <Slider {...newsSettings}>
                      {newsUpdate.map((el, i) => {
                        return (
                          <div key={i} className={style.newsItem}>
                            <NavLink href={el.url} className="p-0 overflow-hidden" target="_">
                              <div>
                                <h6 className="text-center m-1">{el.source}</h6>
                                <p className="p-1">
                                  <strong>{el.title}</strong>
                                </p>
                              </div>
                              <img src={el.img} alt="" />
                            </NavLink>
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <h5 style={{ height: "190px" }}>Currently, No Latest News...</h5>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Container className="py-3">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card className="card-outline card-danger">
                <div className="card-header">
                  <h5 className={"text-center m-0 " + style.cardTitle}>TESTIMONIAL</h5>
                </div>
                <CardContent className="py-2 text-secondary">
                  <Slider className="m-2" {...settings}>
                    {allData.testimonial ? allData.testimonial.map((el, i) => {
                      return (
                        <div key={i}>
                          <div className={style.testimonialItem}>
                            <div className={style.testimonialHead}>
                              {el.logo !== "noLogo.png" ? <img style={{ height: "70px", marginRight: "5px", borderRadius: "5px" }} src={api.companyUrl + el.logo} alt="" /> : ""}
                              <div className="testimonialTitle">
                                <h5 className="m-0 text-primary">
                                  {el.companyName}
                                </h5>
                                <p className="m-0">
                                  {el.fullName}
                                </p>
                                <small>
                                  {el.designation}
                                </small>
                              </div>
                            </div>
                            <p style={{ textAlign: "justify", padding: "5px" }}>{el.message.slice(0, 250) + '...'}</p>
                          </div>
                        </div>
                      )
                    }) : ""}
                  </Slider>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Container> :
      <Container>
        <Grid container style={{ height: "50vh" }} direction="column" justifyContent="center" alignItems="center">
          <Grid item sx={{ position: "relative" }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
              }}
              size={50}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) => (theme.palette.mode === "light" ? "#004395" : "#308fe8"),
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={50}
              thickness={4}
            />
          </Grid>
          <Grid item>
            <img src={api.logosUrl + "/logo.png"} style={{ height: "50px", opacity: "0.9", filter: "drop-shadow(#004395 0px 5px 5px)" }} alt="" />
          </Grid>
        </Grid>
      </Container>
  );
}